import React, { useEffect, useState } from "react"
import { getUser } from "../services/auth"
import Content from "../components/Content"
import styles from "./startlista.module.css"
import Loading from "../components/Loading"
import Layout from "../components/Layout"

export default function StartList() {
  const [state, setState] = useState("idle")
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    setState("loading")
    window
      .fetch(`/api/startlist`)
      .then(response => response.text())
      .then(text => {
        setParticipants(text.split("\n").map(l => l.split(";")))
        setState("idle")
      })
      .catch(err => {
        console.error(err)
        setState("idle")
      })
  }, [])

  return (
    <Layout>
      <Content>
        <p>
          {state === "loading" ? (
            <Loading text="Hämtar startlistan..." />
          ) : null}
        </p>
        <table className={styles.startList}>
          <thead>
            <tr>
              <th>Startnummer</th>
              <th>Namn</th>
              <th>Klubb</th>
            </tr>
          </thead>
          <tbody>
            {participants.map(([startnumber, name, club]) => (
              <tr key={startnumber}>
                <td>{startnumber}</td>
                <td>{name}</td>
                <td>{club}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    </Layout>
  )
}
